var map;
var markerCluster;
var markers = [];

var mcOptions = {
    styles: [
        {
            url: pinUri+"m1.png",
            width: 53,
            height: 53,
            fontFamily: "Helvetica",
            textSize: 15,
            textColor: "white"
        },
        {
            url: pinUri+"m2.png",
            width: 56,
            height: 56,
            fontFamily: "Helvetica",
            textSize: 16,
            textColor: "white"
        }
        ,
        {
            url: pinUri+"m3.png",
            width: 66,
            height: 66,
            fontFamily: "Helvetica",
            textSize: 18,
            textColor: "white"
        }
    ]

};

var gmapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "1"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#b0d0dd"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    }
];


function guessGoogleMapPlaceIcon(iconBase, place) {
    for (var i = 0; i < place.types.length; i++) {
        var type = place.types[i];
        if (type == 'airport' || type == 'bus_station' || type == 'train_station' || type == 'subway_station') {
            return iconBase + '/pin-transport.png';
        } else if (type == 'school' || type == 'university') {
            return iconBase + '/pin-education.png';
        } else if (type == "post_office" || type == "bakery" || type == "bar" || type == "beauty_salon" || type == "bicycle _store" || type == "book_store" || type == "cafe", "clothing_store" || type == "convenience_store" || type == "electronics_store" || type == "florist" || type == "furniture_store" || type == "supermarket" || type == "shopping_mall", "restaurant" || type == "shoe_store" || type == "jewelry_store") {
            return iconBase + '/pin-commerces.png';
        } else if (type == "amusement_park" || type == "aquarium" || type == "art_gallery" || type == "bowling_alley" || type == "casino" || type == "gym" || type == "zoo" || type == "spa" || type == "stadium" || type == "night_club" || type == "museum" || type == "library" || type == "movie_theater") {
            return iconBase + '/pin-loisirs.png';
        }
    }
    return iconBase + '/ico-pin-dark.png';
}

function initMap() {

    if (!$('#map').length) {
        return;
    }

    map = new google.maps.Map(document.getElementById('map'), {
        zoom: 9,
        center: {lat: 44.8637065, lng: -0.6561805},
        styles: gmapStyle,
    });

    var infowindow = new google.maps.InfoWindow({
        maxWidth: 425,
        pixelOffset: new google.maps.Size(0, 190)
    });

    /*
     * The google.maps.event.addListener() event waits for
     * the creation of the infowindow HTML structure 'domready'
     * and before the opening of the infowindow defined styles
     * are applied.
     */
    google.maps.event.addListener(infowindow, 'domready', function () {

        // Reference to the DIV which receives the contents of the infowindow using jQuery
        var iwOuter = $('.gm-style-iw');

        iwOuter.children(':nth-child(1)').css('display', 'block');

        /* The DIV we want to change is above the .gm-style-iw DIV.
         * So, we use jQuery and create a iwBackground variable,
         * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
         */
        var iwBackground = iwOuter.prev();

        // Remove the background shadow DIV
        iwBackground.children(':nth-child(2)').css({'display': 'none'});

        // Remove the white background DIV
        iwBackground.children(':nth-child(4)').css({'display': 'none'});
// Moves the infowindow
        iwOuter.parent().parent().css({left: '-5px'});

        // shadow of the arrow
        iwBackground.children(':nth-child(1)').attr('style', function (i, s) {
            return s + 'display:none!important;'
        });


        iwBackground.children(':nth-child(3)').addClass('iw-arrow');
        // Changes the desired color for the tail outline.
// The outline of the tail is composed of two descendants of div which contains the tail.
// The .find('div').children() method refers to all the div which are direct descendants of the previous div.
        iwBackground.children(':nth-child(3)').find('div').children().css({
            'box-shadow': 'rgba(72, 72, 72, 0.6) 0px 1px 6px',
            'z-index': '1'
        });

        // Taking advantage of the already established reference to
// div .gm-style-iw with iwOuter variable.
// You must set a new variable iwCloseBtn.
// Using the .next() method of JQuery you reference the following div to .gm-style-iw.
// Is this div that groups the close button elements.
        var iwCloseBtn = iwOuter.next().addClass('iw-close');

// The API automatically applies 0.7 opacity to the button after the mouseout event.
// This function reverses this event to the desired value.
        iwCloseBtn.mouseout(function () {
            $(this).css({opacity: '1'});
        });
    });


    // Add the markers and infowindows to the map
    for (var i = 0; i < locations.length; i++) {
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i]['lat'], locations[i]['lng']),
            map: map,
            icon: {
                url: pinUri+'marker-blue.png',
//            url:  pinMapUri,
                size: new google.maps.Size(16, 17),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(8, 17)
            }
        });

        markers.push(marker);

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                infowindow.setContent(locations[i]['content']);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }


    // Add a marker clusterer to manage the markers.
    markerCluster = new MarkerClusterer(map, markers, mcOptions);

    autoCenter(map, markers);


}

function deleteMarker(id) {
    for (var i = 0; i < locations.length; i++) {
        if (locations[i].id == 'pin_'+id) {
            var marker = markers[i]; // find the marker by given id
            marker.setMap(null);
            markerCluster.clearMarkers();
            markers.splice(i, 1);
        }
    }

    markerCluster = new MarkerClusterer(map, markers, mcOptions);
    autoCenter(map, markers);
}

function autoCenter(map, markers) {
    //  Create a new viewpoint bound
    var bounds = new google.maps.LatLngBounds();
    //  Go through each...
    for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].position);
    }
    //  Fit these bounds to the map
    map.fitBounds(bounds);
}
jQuery(function ($) {

    if (typeof google != 'undefined') {
        /*
        *  new_map
        *
        *  This function will render a Google Map onto the selected jQuery element
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	$el (jQuery element)
        *  @return	n/a
        */


        function new_map($el) {
            // var
            var $markers = $el.find('.marker');


            // vars
            var args = {
                zoom: 12,
                center: new google.maps.LatLng(0, 0),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scrollwheel: false,
                streetViewControl: false,
                styles: gmapStyle
            }


            // create map
            var map = new google.maps.Map($el[0], args);


            // add a markers reference
            map.markers = [];


            // add markers
            $markers.each(function () {
                add_marker($(this), map);
            });


            // center map
            center_map(map);

            // return
            return map;
        }

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	$marker (jQuery element)
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function add_marker($marker, map) {

            // var
            var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

            var marker_img = {
                // url: localPath.templateUrl + pinUri+'marker.png',
                url:  pinUri+'ico-pin-dark.png',
                size: new google.maps.Size(38, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(19, 48)
            };



            // create marker
            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: marker_img,
            });

            // add to array
            map.markers.push(marker);

            // if marker contains HTML, add it to an infoWindow
            if ($marker.html()) {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                    content: $marker.html()
                });

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function () {

                    infowindow.open(map, marker);

                });
            }

        }

        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function center_map(map) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each(map.markers, function (i, marker) {

                var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

                bounds.extend(latlng);

            });

            // only 1 marker?
            if (map.markers.length == 1) {
                // set center of map
                map.setCenter(bounds.getCenter());
                map.setZoom(16);
            }
            else {
                // fit to bounds
                map.fitBounds(bounds);
            }

        }

        /*
        *  document ready
        *
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	5.0.0
        *
        *  @param	n/a
        *  @return	n/a
        */
        // global var
        var map = null;

        $(document).ready(function () {


            $('.map').each(function () {
                // create map
                map = new_map($(this));
            });

            if ($('#map-canvas').length) {

                var mapDatas = new google.maps.Map(document.getElementById("map-canvas"), {
                    zoom: 16,
                    center: {"lat": datalat, "lng": datalng},
                    mapTypeControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    scrollwheel: false,
                    streetViewControl: false,
                    styles: gmapStyle
                });
                var markerDatas_img = {
                    url: pinUri+'ico-pin.png',
                    size: new google.maps.Size(38, 48),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(19, 48)
                };
                var markerDatas = new google.maps.Marker({
                    position: {"lat": datalat, "lng": datalng},
                    map: mapDatas,
                    icon: markerDatas_img,
                });
                var googleMapTransportList = [];
            }




            function googleMapTransportListServicesCallback(results, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        var markerplaceDatas = new google.maps.Marker({
                            position: place.geometry.location,
                            title: place.name,
                            icon: guessGoogleMapPlaceIcon(pinUri, place),
                            map: mapDatas
                        });
                        googleMapTransportList.push(markerplaceDatas);
                    }
                }
            }

            jQuery("#input-transport").on("change", function () {
                if (jQuery("#input-transport:checked").length >= 1) {
                    var request = {
                        location: new google.maps.LatLng(datalat, datalng),
                        radius: 1000,
                        types: ["airport", "bus_station", "train_station", "subway_station"]
                    };
                    service = new google.maps.places.PlacesService(mapDatas);
                    service.nearbySearch(request, googleMapTransportListServicesCallback);
                } else {
                    if (googleMapTransportList.length > 0) {
                        for (var i = 0; i < googleMapTransportList.length; i++) {
                            googleMapTransportList[i].setMap(null);
                        }
                        googleMapTransportList = [];
                    }
                }
            });

            var googleMapEcolesList = [];

            function googleMapEcolesListServicesCallback(results, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        var markerplaceDatas = new google.maps.Marker({
                            position: place.geometry.location,
                            title: place.name,
                            icon: guessGoogleMapPlaceIcon(pinUri, place),
                            map: mapDatas
                        });
                        googleMapEcolesList.push(markerplaceDatas);
                    }
                }
            }

            jQuery("#input-ecoles").on("change", function () {
                if (jQuery("#input-ecoles:checked").length >= 1) {
                    var request = {
                        location: new google.maps.LatLng(datalat, datalng),
                        radius: 1000,
                        types: ["school", "university"]
                    };
                    service = new google.maps.places.PlacesService(mapDatas);
                    service.nearbySearch(request, googleMapEcolesListServicesCallback);
                } else {
                    if (googleMapEcolesList.length > 0) {
                        for (var i = 0; i < googleMapEcolesList.length; i++) {
                            googleMapEcolesList[i].setMap(null);
                        }
                        googleMapEcolesList = [];
                    }
                }
            });


            var googleMapCommercesList = [];

            function googleMapCommercesListServicesCallback(results, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        var markerplaceDatas = new google.maps.Marker({
                            position: place.geometry.location,
                            title: place.name,
                            icon: guessGoogleMapPlaceIcon(pinUri, place),
                            map: mapDatas
                        });
                        googleMapCommercesList.push(markerplaceDatas);
                    }
                }
            }

            jQuery("#input-commerces").on("change", function () {
                if (jQuery("#input-commerces:checked").length >= 1) {
                    var request = {
                        location: new google.maps.LatLng(datalat, datalng),
                        radius: 1000,
                        types: ["post_office", "bakery", "bar", "beauty_salon", "bicycle _store", "book_store", "cafe", "clothing_store", "convenience_store", "electronics_store", "florist", "furniture_store", "supermarket", "shopping_mall", "restaurant", "shoe_store", "jewelry_store"]
                    };
                    service = new google.maps.places.PlacesService(mapDatas);
                    service.nearbySearch(request, googleMapCommercesListServicesCallback);
                } else {
                    if (googleMapCommercesList.length > 0) {
                        for (var i = 0; i < googleMapCommercesList.length; i++) {
                            googleMapCommercesList[i].setMap(null);
                        }
                        googleMapCommercesList = [];
                    }
                }
            });


            var googleMapLoisirsList = [];

            function googleMapLoisirsListServicesCallback(results, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        var markerplaceDatas = new google.maps.Marker({
                            position: place.geometry.location,
                            title: place.name,
                            icon: guessGoogleMapPlaceIcon(pinUri, place),
                            map: mapDatas
                        });
                        googleMapLoisirsList.push(markerplaceDatas);
                    }
                }
            }

            jQuery("#input-loisirs").on("change", function () {
                if (jQuery("#input-commerces:checked").length >= 1) {
                    var request = {
                        location: new google.maps.LatLng(datalat, datalng),
                        radius: 1000,
                        types: ["amusement_park", "aquarium", "art_gallery", "bowling_alley", "casino", "gym", "zoo", "spa", "stadium", "night_club", "museum", "library", "movie_theater"]
                    };
                    service = new google.maps.places.PlacesService(mapDatas);
                    service.nearbySearch(request, googleMapLoisirsListServicesCallback);
                } else {
                    if (googleMapLoisirsList.length > 0) {
                        for (var i = 0; i < googleMapLoisirsList.length; i++) {
                            googleMapLoisirsList[i].setMap(null);
                        }
                        googleMapLoisirsList = [];
                    }
                }
            });





        });
    }


});
