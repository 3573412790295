;(function ($, window, document, undefined) {
    var $win = $(window);
    var $doc = $(document);


    var $status = $('.pagingInfo');

    var slideWrapper = $(".inner-media .slider__slides"),
        iframes = slideWrapper.find('.embed-player'),
        lazyImages = slideWrapper.find('.slider__slide.image'),
        lazyCounter = 0;

    // Load AJAX data
    function loadAjax(filePath, data, method) {
        var method = method ? method : 'POST';
        return $.ajax({
            'async': false,
            'global': false,
            'url': filePath,
            'data': data
        }).responseJSON;
    }

    function select() {
        if ($('select').length) {
            $('.select select, .select-alt select').select2({
                minimumResultsForSearch: 20,
            });
            $('.select-custom select').select2({
                minimumResultsForSearch: 20,
                dropdownCssClass: 'custom-dropdown'
            });
        }
    }

    function inlineBg() {
        $("[data-bg]").each(function () {
            var dataImg = $(this).data('bg');
            $(this).css('background-image', 'url(' + dataImg + ')');
        });
    }

    function toggleMenu() {

        $('.toggle-menu').toggleClass('active');
        $('.toggle-contact, .toggle-account').removeClass('active');
        $('.header').toggleClass('opened-menu');
        $('.header').removeClass('opened-contact');
        $('.header').removeClass('opened-account');

        if ($('.header').hasClass('opened-menu')) {
            $('body').addClass('no-scroll');
            $('body').addClass('menu');
        } else {
            $('body').removeClass('no-scroll');
            $('body').removeClass('menu');
        }
        /*$('.header .menu-main li.menu-item-has-children .submenu').slideUp();*/
    }

    function toggleContact() {
        $('.toggle-contact, .header-contact .menu-close').on('click', function (e) {
            e.preventDefault();

            $('.toggle-contact').toggleClass('active');
            $('.toggle-menu, .toggle-account').removeClass('active');
            $('.header').toggleClass('opened-contact');
            $('.header').removeClass('opened-menu');
            $('.header').removeClass('opened-account');

            if ($('.header').hasClass('opened-contact')) {
                $('body').addClass('no-scroll');
                $('body').addClass('menu');
            } else {
                $('body').removeClass('no-scroll');
                $('body').removeClass('menu');
            }
        });
    }

    function toggleAccount() {
        $('.toggle-account, .header-account .menu-close').on('click', function (e) {
            e.preventDefault();

            $('.toggle-account').toggleClass('active');
            $('.toggle-menu, .toggle-contact').removeClass('active');
            $('.header').toggleClass('opened-account');
            $('.header').removeClass('opened-menu');
            $('.header').removeClass('opened-contact');

            if ($('.header').hasClass('opened-account')) {
                $('body').addClass('no-scroll');
                $('body').addClass('menu');
            } else {
                $('body').removeClass('no-scroll');
                $('body').removeClass('menu');
            }
        });
    }

    function toggleSubMenu() {
        $('.header .menu-main li.menu-item-has-children >a').on('click', function (e) {
            e.preventDefault();
            $('.header .menu-main li.menu-item-has-children').not($(this).closest('li')).find('.submenu').slideUp();
            $('.header .menu-main li.menu-item-has-children').not($(this).closest('li')).removeClass('current-menu-item');
            $(this).closest('li').find('.submenu').slideToggle();
            $(this).closest('li').toggleClass('current-menu-item');
        });
    }

    function modalVideo() {
        // search modal
        $('.video-toggle').on('click', function (e) {
                e.preventDefault();
                var video = $(this).data('video');
                $("#modal-video iframe").attr('src', video + '?rel=0&modestbranding=1&byline=0&portrait=0&autoplay=1');
                var videoTitle = $(this).data('video-title');
                var videoDesc = $(this).data('video-desc');

                $("#modal-video .video-title").html(videoTitle);
                $("#modal-video .video-desc").html(videoDesc);

                // Show modal
                $('#modal-video').modal();

                $('#modal-video').on('hidden.bs.modal', function () {
                    $("#modal-video iframe").attr('src', '');
                    $("#modal-video .video-title").html('');
                    $("#modal-video .video-desc").html('');
                });
            }
        );
    }

    function modalImage() {
        // search modal
        $('.thumb-toggle').on('click', function (e) {
                e.preventDefault();
                var image = $(this).data('src');
                var caption = $(this).data('caption');
                $("#modal-img img").attr('src', image);
                $("#modal-img .caption").html(caption);
                // Show modal
                $('#modal-img').modal();

                $('#modal-img').on('hidden.bs.modal', function () {
                    $("#modal-img img").attr('src', '');
                    $("#modal-img .caption").html('');
                });
            }
        );
    }

    function modalDefault() {
        // search modal
        $('.content-toggle').on('click', function (e) {
                e.preventDefault();
                var content = $(this).closest('.slider__slide').find('.slider__slide-image').html();
                $("#modal-default .modal-content-default").append(content);

                // Show modal
                $('#modal-default').modal();

                $('#modal-default').on('hidden.bs.modal', function () {
                    $("#modal-default .modal-content-default").html('');
                });
            }
        );
    }

    function modalGallery() {
        // search modal
        $('.gallery-toggle').on('click', function (e) {
            e.preventDefault();
            var index = $(this).data('index');
            var slider = $(this).closest('.gallery-slider-ctn').find('.gallery-slider .slick-track').html();


            $("#modal-gallery .modal-slider").append(slider);


            $('.modal-slider').children().removeClass('slick-slide').removeClass('slick-active').removeClass('slick-cloned').removeAttr('data-slick-index').removeAttr('data-target').removeAttr('aria-hidden').removeAttr('tabindex').removeAttr('data-index').removeAttr('data-toggle').removeAttr('style').children().removeAttr('data-bg').removeClass('hidden').removeAttr('style');

            setTimeout(function () {
                $("#modal-gallery .modal-slider").slick({
                    dots: false,
                    arrows: true,
                    initialSlide: index
                });
            }, 300);
            // Show modal
            $('#modal-gallery').modal();

            $('#modal-gallery').on('hidden.bs.modal', function () {
                $("#modal-gallery .modal-slider").slick('unslick');
                $("#modal-gallery .modal-slider").html('');
            });
        });
    }

    function modalPlan() {
        // search modal
        $('.plan-toggle').on('click', function (e) {
                e.preventDefault();
                var program = $(this).data('program');
                var programLot = $(this).data('lot');

                $('#modal-plan').find('.modal-title .title-program').html(program);
                $('#modal-plan').find('.modal-title .title-lot').html(programLot);
                $('#modal-plan').find('input#plan-program').val(program);
                $('#modal-plan').find('input#plan-program-lot').val(programLot);
                // Show modal
                $('#modal-plan').modal();

                $('#modal-plan').on('hidden.bs.modal', function () {
                    // $("#modal-plan form .plan-program").attr('src', '');
                    $('#modal-plan').find('.modal-title .title-program').html();
                    $('#modal-plan').find('.modal-title .title-lot').html();
                    $('#modal-plan').find('input#plan-program').val();
                    $('#modal-plan').find('input#plan-program-lot').val();
                });
            }
        );
    }

    function modalMonthlyCalc() {
        // search modal
        $('.monthly-calc-toggle').on('click', function (e) {
            e.preventDefault();

            // Show modal
            $('#modal-monthly-calc').modal();

            $('#modal-monthly-calc').on('hidden.bs.modal', function () {

            });
        });
    }

    function modalCapCalc() {
        // search modal
        $('.cap-calc-toggle').on('click', function (e) {
            e.preventDefault();

            // Show modal
            $('#modal-cap-calc').modal();

            $('#modal-cap-calc').on('hidden.bs.modal', function () {

            });
        });
    }

    function modalNotaryCalc() {
        // search modal
        $('.notary-calc-toggle').on('click', function (e) {
            e.preventDefault();

            // Show modal
            $('#modal-notary-calc').modal();

            $('#modal-notary-calc').on('hidden.bs.modal', function () {

            });
        });
    }

    function smoothScroll() {
        $(document).on('click', 'a[href*="#"]:not([href="#"]):not(.nosmooth)', function (e) {
            e.preventDefault();
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 50
                    }, 800);
                    return false;
                }
            }
        });
    }


    function projectsSlider() {

        // Initialize
        slideWrapper.on("init", function (slick) {
            slick = $(slick.currentTarget);
            setTimeout(function () {
                playPauseVideo(slick, "play");
            }, 1000);
            resizePlayer(iframes, 16 / 9);
        });
        slideWrapper.on("beforeChange", function (event, slick) {
            slick = $(slick.$slider);
            playPauseVideo(slick, "pause");
        });
        slideWrapper.on("afterChange", function (event, slick) {
            slick = $(slick.$slider);
            playPauseVideo(slick, "play");
        });
        slideWrapper.on("lazyLoaded", function (event, slick, image, imageSource) {
            lazyCounter++;
            if (lazyCounter === lazyImages.length) {
                lazyImages.addClass('show');
                // slideWrapper.slick("slickPlay");
            }
        });

        //start the slider
        slideWrapper.slick({
            // fade:true,
            lazyLoad: "progressive",
            speed: 600,
            arrows: true,
            dots: false,
            /*  prevArrow: '<div class="arrow-slider arrow-slider--left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" class="ico ico-arrow"><path d="M22.81,3.93,12.57,14.28a.78.78,0,0,1-1.1,0L1.22,3.93a.78.78,0,0,1,0-1.1l1.1-1.1a.78.78,0,0,1,1.1,0l8.05,8.14a.78.78,0,0,0,1.1,0l8-8.1a.78.78,0,0,1,1.1,0l1.1,1.1a.83.83,0,0,1,0,1Z"/></svg></div>',
             nextArrow: '<div class="arrow-slider arrow-slider--right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" class="ico ico-arrow"><path d="M22.81,3.93,12.57,14.28a.78.78,0,0,1-1.1,0L1.22,3.93a.78.78,0,0,1,0-1.1l1.1-1.1a.78.78,0,0,1,1.1,0l8.05,8.14a.78.78,0,0,0,1.1,0l8-8.1a.78.78,0,0,1,1.1,0l1.1,1.1a.83.83,0,0,1,0,1Z"/></svg></div>',*/
            // cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        });


// Resize event
        $(window).on("resize.slickVideoPlayer", function () {
            resizePlayer(iframes, 16 / 9);
        });
    }

    // POST commands to YouTube or Vimeo API
    function postMessageToPlayer(player, command) {
        if (player == null || command == null) return;
        player.contentWindow.postMessage(JSON.stringify(command), "*");
    }

// When the slide is changing
    function playPauseVideo(slick, control) {
        var currentSlide, slideType, startTime, player, video;

        currentSlide = slick.find(".slick-current");
        slideClass = currentSlide.attr("class");
        if (slideClass) {
            slideType = slideClass.split(" ")[1];
            player = currentSlide.find("iframe").get(0);
            startTime = currentSlide.data("video-start");
        }

        if (slideType === "vimeo") {
            switch (control) {
                case "play":
                    if ((startTime != null && startTime > 0) && !currentSlide.hasClass('started')) {
                        currentSlide.addClass('started');
                        postMessageToPlayer(player, {
                            "method": "setCurrentTime",
                            "value": startTime
                        });
                    }
                    postMessageToPlayer(player, {
                        "method": "play",
                        "value": 1
                    });
                    break;
                case "pause":
                    postMessageToPlayer(player, {
                        "method": "pause",
                        "value": 1
                    });
                    break;
            }
        } else if (slideType === "youtube") {
            switch (control) {
                case "play":
                    postMessageToPlayer(player, {
                        "event": "command",
                        "func": "mute"
                    });
                    postMessageToPlayer(player, {
                        "event": "command",
                        "func": "playVideo"
                    });
                    break;
                case "pause":
                    postMessageToPlayer(player, {
                        "event": "command",
                        "func": "pauseVideo"
                    });
                    break;
            }
        } else if (slideType === "video") {
            video = currentSlide.children("video").get(0);
            if (video != null) {
                if (control === "play") {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    }

// Resize player
    function resizePlayer(iframes, ratio) {
        if (!iframes[0]) return;
        var win = slideWrapper,
            width = win.width(),
            playerWidth,
            height = win.height(),
            playerHeight,
            ratio = ratio || 16 / 9;

        iframes.each(function () {
            var current = $(this);
            if (width / ratio < height) {
                playerWidth = Math.ceil(height * ratio);
                current.width(playerWidth).height(height).css({
                    left: (width - playerWidth) / 2,
                    top: 0
                });
            } else {
                playerHeight = Math.ceil(width / ratio);
                current.width(width).height(playerHeight).css({
                    left: 0,
                    top: (height - playerHeight) / 2
                });
            }
        });
    }

    function cleaveInput() {
        $('.input-price').each(function () {
            new Cleave($(this), {
                numeral: true,
                numeralDecimalMark: ',',
                delimiter: ' '
            });
        });

        $('.input-decimal').each(function () {
            new Cleave($(this), {
                numeral: true,
                numeralDecimalMark: ',',
                numeralPositiveOnly: true
            });
        });

        $('.input-number').each(function () {
            new Cleave($(this), {
                numeral: true,
                numeralDecimalMark: ' ',
                numeralPositiveOnly: true
            });
        });

    }

    function simulationForms() {
        $(document).on('click', '.simulation-form-submit', function (e) {

            $('.modal-dialog .loader-box-container').remove();

            e.preventDefault();
            var cForm = $(this).closest('form');
            var cFormContainer = cForm.attr('data-form');
            if (cFormContainer) {
                $(".loader-box-container").clone().insertAfter(cForm);
                $('#' + cFormContainer).addClass('loading');
                // Populate Empty
                cForm.find('.input-values').each(function () {
                    if ($(this).val() == '') {
                        $(this).val($(this).attr('placeholder'));
                    }
                });

                var vFormCalculation = cForm.serialize();
                // Call Calculation
                //var response = loadAjax(ajaxSyncUrl, vFormCalculation);
                $.ajax({
                    type: "POST",
                    url: ajaxSyncUrl,
                    data: cForm.serialize(), // serializes the form's elements.
                    success: function (response) {
                        // RESET
                        $('#' + cFormContainer).removeClass('loading');
                        $('#' + cFormContainer).find('.form-result').show();
                        $('#' + cFormContainer).find('.result-hightlight').html('');
                        $('#' + cFormContainer).find('.result-hightlight').show();
                        $('#' + cFormContainer).find('.result-msg').html('');
                        $('#' + cFormContainer).find('.result-msg').removeClass('error');
                        if (!response.success) {
                            $('#' + cFormContainer).find('.result-hightlight').html('').hide();
                            $('#' + cFormContainer).find('.result-msg').addClass('error');
                            $('#' + cFormContainer).find('.result-msg').html(response.data);
                            return;
                        }
                        var dataResponseType = response.data.type;
                        var dataResponse = response.data.amount;
                        if (dataResponseType == 'monthly' || dataResponseType == 'notary') {
                            $('#' + cFormContainer).find('.result-hightlight').html(dataResponse);
                        }
                        if (dataResponseType == 'capability') {
                            $('#capability_price').html(dataResponse.price);
                            $('#capability_total').html(dataResponse.total);
                            $('#capability_grandtotal').html(dataResponse.grandtotal);
                        }

                    },
                    error: function (response) {
                        // RESET
                        $('#' + cFormContainer).removeClass('loading');
                        $('#' + cFormContainer).find('.form-result').show();
                        $('#' + cFormContainer).find('.result-hightlight').html('');
                        $('#' + cFormContainer).find('.result-hightlight').show();
                        $('#' + cFormContainer).find('.result-msg').html('');
                        $('#' + cFormContainer).find('.result-msg').removeClass('error');
                    }
                });

                $('#' + cFormContainer).removeClass('loading');

            }
            return false;
        });
    }

    function loadInlinePrograms() {
        $('.inline-load-more').on('click', function () {
            var curPage = $(this).attr('data-nb');
            curPage++;
            var maxPages = $(this).attr('data-max-nb');
            if (curPage > maxPages) {
                $(this).fadeOut(250, function () {
                    $(this).remove();
                });
            }
            else {
                $("[data-line-load=" + curPage + "]").removeClass('line-hidden');
                $(this).attr('data-nb', curPage);
                if (curPage == maxPages) {
                    $(this).remove();
                }
            }
        });
    }

    function dropdownMobile() {
        if ($win.width() < 992) {
            $('.dropdown-list-ctn').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('toggled');
            });
        }
    }


    function sendForm(cForm) {
        var cFormContainer = cForm.attr('data-form');
        var cModalType = false;
        var successMsg = '';
        if (cFormContainer) {
            $('.modal-dialog .loader-box-container').remove();
            $(".loader-box-container").clone().insertAfter(cForm);
            $('#' + cFormContainer).addClass('loading');
            cModalType = true;
            successMsg = successPlanMsg;
        }
        else {
            $('body').addClass('loading');
        }

        $.ajax({
            type: "POST",
            url: ajaxSyncUrl,
            data: cForm.serialize(), // serializes the form's elements.
            success: function (response) {
                if (response.success && response.data) {
                    console.log(cFormContainer);
                    if (cFormContainer == 'modal-view') {
                        $('#iframe-view').css('display', 'block');
                        $('#view_form').css('display', 'none');
                    }
                    if (cFormContainer == 'modal-virtual') {
                        $('#iframe-virtual').css('display', 'block');
                        $('#virtual_form').css('display', 'none');
                    }
                    if (response.data.type == 'success') {
                        if (cModalType) {
                            cForm.find("input[type=text], textarea, input[type=email]").val("");
                            cForm.before('<div class="sucess-msg">' + response.data.message + '</div>');
                        }
                        else {
                            if (cFormContainer == 'modal-view') {
                                $('#iframe-view').css('display', 'block');
                                $('#view_form').css('display', 'none');
                            }
                            if (cFormContainer == 'modal-virtual') {
                                $('#iframe-virtual').css('display', 'block');
                                $('#virtual_form').css('display', 'none');
                            }

                            cForm.slideUp(250);
                            cForm.before('<div class="sucess-msg">' + response.data.message + '</div>');
                        }
                    }
                    else {
                        alert(response.data.message);
                    }
                }
                else {
                    alert('Erreur générale');
                }
            },
            error: function (response) {
                alert('Erreur générale');
            }
        });

        if (cModalType) {
            $('#' + cFormContainer).removeClass('loading');
        }
        else {
            $('body').removeClass('loading');
        }

    }

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide.",
        url: "Veuillez fournir une adresse URL valide.",
        number: "Veuillez fournir un numéro valide.",
        digits: "Veuillez fournir seulement des chiffres."
    });

    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#f_cgv').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    setTimeout(function () {
        $('#f_fname').rules('add', {required: true});
        $('#f_lname').rules('add', {required: true});
        $('#f_tel').rules('add', {required: true});
        $('#f_email').rules('add', {required: true, email: true});

        $('#f_cgv').rules('add', {
            validateCgv: true
        });

        $('#f_recaptcha').rules('add', {
            validateRecaptcha: true
        });
    }, 100);

    $("form.validate").each(function () {
        var currentForm = $(this);
        currentForm.validate({
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    error.addClass("checkbox");
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                sendForm(currentForm);
            }
        });
    });

    $doc.ready(function () {
        $('.tabs-header a').on('click', function (e) {
            e.preventDefault();
            $('.tabs-header a').not($(this)).removeClass('active');
            $(this).addClass('active');
            var content = $(this).attr('href');
            $('.tabs-content .content-form:not(' + content + ')').slideUp();
            $('.tabs-content .content-form' + content).slideDown();
        });
        $('.accordion').on('click', '.accordion__head', function () {
            $(this)
                .closest('.accordion')
                .toggleClass('active')
                .find('.accordion__body')
                .slideToggle()
                .closest('.accordion')
                .siblings()
                .removeClass('active')
                .find('.accordion__body')
                .slideUp()
        });

        if ($win.width() < 768) {
            $('.form-filter .form__link').on('click', function (e) {
                e.preventDefault();

                $(this).closest('.form-filter').toggleClass('active').find('form').slideToggle()
            });
        }

        smoothScroll();
        inlineBg();
        select();
        //clearFilters();

        $('.toggle-menu, .header-menu .menu-close').on('click', function (e) {
            e.preventDefault();
            toggleMenu();
        });

        $('.wrapper').on('click', function (e) {
            if ($('body').hasClass('menu')) {
                e.preventDefault();
                toggleMenu();
            }
        });

        toggleSubMenu();
        toggleContact();
        toggleAccount();


        if ($('.slider-alt').length) {
            $('.slider-alt .slider__slides').each(function (index) {
                $this = $(this);
                if ($this.find('.slider__slide').length > 1) {
                    $this.slick({
                        slidesToShow: 3,
                        dots: false,
                        responsive: [
                            {
                                breakpoint: 991,
                                settings: {
                                    dots: true,
                                    slidesToShow: 2
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    dots: true,
                                    slidesToShow: 1
                                }
                            }
                        ]
                    });
                }
            });
        }

        if ($('.slider-def').length) {
            $('.slider-def .slider__slides').each(function (index) {
                $this = $(this);
                if ($this.find('.slider__slide').length > 1) {
                    $this.slick({
                        slidesToShow: 3,
                        dots: false,
                        responsive: [
                            {
                                breakpoint: 991,
                                settings: {
                                    dots: true,
                                    slidesToShow: 2
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    dots: true,
                                    slidesToShow: 1
                                }
                            }
                        ]
                    });
                }
            });
        }

        if ($('.slider-quotes').length) {
            $('.slider-quotes .slider__slides').each(function (index) {
                $this = $(this);
                if ($this.find('.slider__slide').length > 1) {
                    $this.slick({
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    });
                }
            });
        }

        if ($('.slider').length) {
            $('.slider .slider__slides').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $('.pagingInfo').html(i + ' / ' + slick.slideCount);
            });
            $('.slider .slider__slides').each(function (index) {
                $this = $(this);
                if ($this.find('.slider__slide').length > 1) {
                    $this.slick({
                        slidesToShow: 1,
                        dots: true
                    });
                }
            });
        }

        if ($('ul.list-filters').length) {

            var currentProjectView;
            $('ul.list-filters').find('li').each(function () {
                if ($(this).hasClass('active')) {
                    currentProjectView = $(this).attr('id');
                    return;
                }
            });
            $('a.change-list-view').on('click', function () {
                var reqProjectView = $(this).parent().attr('id');
                if (reqProjectView != currentProjectView) {
                    $('ul.list-filters li').removeClass('active');
                    $(this).parent().addClass('active');
                    currentProjectView = reqProjectView;
                    changeListView(currentProjectView, map, markers);
                }
            });

            $('#remove_filters').on('click', function () {
                // 455
                deleteMarker(455);
            });
        }

        function changeListView(view, map, markers) {
            $('#wrapper_project_list').fadeOut(400).slideUp(400, function () {
                $('.box-filtered-project').css('opacity', 0);
                $('.box-filtered-project').hide();
                $("[data-listview=" + view + "]").show();
                $('#wrapper_project_list').fadeIn(0).slideDown(400);
                if (view == 'map_view_map') {
                    // Add Form Trigger
                    var formAction = $('#form_filter_projects').attr('action');
                    $('#form_filter_projects').attr('action', formAction + '?map');
                    $('#wrapper_project_list').addClass('no-marge');
                    setTimeout(function () {
                        autoCenter(map, markers);
                        $("[data-listview=" + view + "]").css('opacity', 1);
                    }, 200);
                }
                else {
                    $('#wrapper_project_list').removeClass('no-marge');
                    $("[data-listview=" + view + "]").css('opacity', 1);
                    $("[data-listview=" + view + "]").css('opacity', 1);
                    // Remove Form Trigger
                    var formAction = $('#form_filter_projects').attr('action');
                    formAction = formAction.replace('?map', '');
                    $('#form_filter_projects').attr('action', formAction);

                }

            });
        }

        function newsPaginate() {
            $(document).on('click', '#news_btn_link a', function(e) {
                e.preventDefault();
                var pageUri = $(this).attr('href');

                $('body').addClass('loading');
                $('#news_btn_link').remove();

                $.ajax({ type: "GET",
                     url: pageUri,
                     success : function(result) {
                         $('#list_news_container').append(result);
                         $('body').removeClass('loading');
                     }
                });
                return false;
            });
        }


        function bookmarkBtn() {
            $('#bookmarkme').on('click', function() {
                var isActiv = $(this).hasClass('active');
                $.ajax({
                    type: "POST",
                    url: ajaxSyncUrl,
                    data: {'bookmark':{'program':currentOffer, 'state':isActiv}},
                    success: function (response) {
                    }
                });

                if (isActiv) {
                    $(this).find('span').text(bookMarkeLabel);
                    $(this).removeClass('active');
                }
                else {
                    $(this).find('span').text(removeBookMarkeLabel);
                    $(this).addClass('active');
                }
            });

            $('.remove-bookmark').on('click', function(e) {
                e.preventDefault();
                var currentOffer = $(this).attr('data-remove');
                if (currentOffer) {
                    $.ajax({
                        type: "POST",
                        url: ajaxSyncUrl,
                        data: {'bookmark_delete':{'program':currentOffer}},
                        success: function (response) {
                        }
                    });
                    $(this).closest('.card--program').remove();
                    if (!$('.wrapper-main .main-list .card--program').length) {
                        $('#no_items_bookmarks').show();
                    }
                    return;
                }
            });

        }


        modalDefault();
        modalVideo();
        modalImage();
        modalGallery();
        modalPlan();
        modalMonthlyCalc();
        modalCapCalc();
        modalNotaryCalc();

        dropdownMobile();

        cleaveInput();
        simulationForms();

        loadInlinePrograms();

        projectsSlider();

        newsPaginate();

        bookmarkBtn();
    });


    $win.on('load', function () {


        var urlHash = window.location.href.split("#")[1];
        if (urlHash && $('#' + urlHash).length) {
            $('html,body').animate({
                scrollTop: $('#' + urlHash).offset().top - 280
            }, 800);
        }

    }).on('load resize', function () {
        if ($win.width() < 768) {
            if (!$('.services').hasClass('slick-slider')) {
                $('.services').slick({
                    slidesToShow: 1,
                    dots: true
                });
            }
        } else if ($win.width() > 767 && $win.width() < 992) {
            if (!$('.services').hasClass('slick-slider')) {
                $('.services').slick({
                    slidesToShow: 3,
                    dots: true
                });
            }
        } else {
            if ($('.services').hasClass('slick-slider')) {
                $('.services').slick('destroy')
            }
        }
    })

})(jQuery, window, document);
